import styles from "./Items.module.css";
import relaciones from "../assets/relaciones.svg";
import descarbonizacion from "../assets/descarbonizacion.svg";
import negociacion from "../assets/handshake.svg";
import finanzas from "../assets/finance.svg";
import eco from "../assets/eco.svg";
import constructor from "../assets/constructor.svg";
import trabajo from "../assets/trabajo.svg";
import logo from "../assets/logo.png";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Items (){
    const {t} = useTranslation(['items']);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
    return (
        <section className={styles.items}>
            <div className={styles.mainCircle}>
 
                <div className={`${styles.one} ${styles.item} 
                        relative
                        before:content-[attr(data-tip)]
                        before:absolute
                        before:px-2 before:py-2
                        before:left-1/2 before:-top-3
                        before:w-max before:max-w-xs
                        before:-translate-x-1/2 before:-translate-y-full
                        before:bg-main-color before:text-white
                        before:rounded-md before:opacity-0
                        before:transition-all

                        after:absolute
                        after:left-1/2 after:-top-3
                        after:h-0 after:w-0
                        after:-translate-x-1/2 after:border-8
                        after:border-t-main-color
                        after:border-l-transparent
                        after:border-b-transparent
                        after:border-r-transparent
                        after:opacity-0
                        after:transition-all

                        hover:before:opacity-100 hover:after:opacity-100

                    `} data-tip = {t('item')}>
                    <NavLink to="/whatwedo#1" onClick={scrollToTop}>
                        <img src={trabajo} alt="Icono de trabajo" />
                    </NavLink>
                </div>
                
                {/* <p className={styles.pOne}>{t('item')}</p> */}


                <div className={`${styles.two} ${styles.item2}
                        relative
                        before:content-[attr(data-tip)]
                        before:absolute
                        before:px-2 before:py-2
                        before:left-1/2 before:-top-3
                        before:w-max before:max-w-xs
                        before:-translate-x-1/2 before:-translate-y-full
                        before:bg-main-color before:text-white
                        before:rounded-md before:opacity-0
                        before:transition-all

                        after:absolute
                        after:left-1/2 after:-top-3
                        after:h-0 after:w-0
                        after:-translate-x-1/2 after:border-8
                        after:border-t-main-color
                        after:border-l-transparent
                        after:border-b-transparent
                        after:border-r-transparent
                        after:opacity-0
                        after:transition-all

                        hover:before:opacity-100 hover:after:opacity-100
                    `} data-tip = {t('item2')}>
                    <NavLink to={'/whatwedo'}onClick={scrollToTop}>
                        <img src={descarbonizacion} alt="Icono de Descarbonización" />
                    </NavLink>
                </div>
                {/* <p className={styles.pTwo}>{t('item2')}</p> */}

                <div className={`${styles.three} ${styles.item2}
                    relative
                    before:content-[attr(data-tip)]
                    before:absolute
                    before:px-2 before:py-2
                    before:left-1/2 before:-top-3
                    before:w-max before:max-w-xs
                    before:-translate-x-1/2 before:-translate-y-full
                    before:bg-main-color before:text-white
                    before:rounded-md before:opacity-0
                    before:transition-all

                    after:absolute
                    after:left-1/2 after:-top-3
                    after:h-0 after:w-0
                    after:-translate-x-1/2 after:border-8
                    after:border-t-main-color
                    after:border-l-transparent
                    after:border-b-transparent
                    after:border-r-transparent
                    after:opacity-0
                    after:transition-all

                    hover:before:opacity-100 hover:after:opacity-100
                `} data-tip={t('item3')}>
                    <NavLink to={'/whatwedo'} onClick={scrollToTop}> 
                        <img src={finanzas} alt="Icono de finanzas" />
                    </NavLink>
                </div>
                {/* <p className={styles.pThree}>{t('item3')}</p> */}

                <div className={`${styles.four} ${styles.item}
                    relative
                    before:content-[attr(data-tip)]
                    before:absolute
                    before:px-2 before:py-2
                    before:left-1/2 before:-top-3
                    before:w-max before:max-w-xs
                    before:-translate-x-1/2 before:-translate-y-full
                    before:bg-main-color before:text-white
                    before:rounded-md before:opacity-0
                    before:transition-all

                    after:absolute
                    after:left-1/2 after:-top-3
                    after:h-0 after:w-0
                    after:-translate-x-1/2 after:border-8
                    after:border-t-main-color
                    after:border-l-transparent
                    after:border-b-transparent
                    after:border-r-transparent
                    after:opacity-0
                    after:transition-all

                    hover:before:opacity-100 hover:after:opacity-100
                `} data-tip={t('item4')}>
                    <NavLink to={'/whatwedo'} onClick={scrollToTop}>
                        <img src={constructor} alt="Icono de constructor" />
                    </NavLink>
                </div>
                {/* <p className={styles.pFour}>{t('item4')}</p> */}

                <div className={`${styles.five} ${styles.item2}
                    relative
                    before:content-[attr(data-tip)]
                    before:absolute
                    before:px-2 before:py-2
                    before:left-1/2 before:-top-3
                    before:w-max before:max-w-xs
                    before:-translate-x-1/2 before:-translate-y-full
                    before:bg-main-color before:text-white
                    before:rounded-md before:opacity-0
                    before:transition-all

                    after:absolute
                    after:left-1/2 after:-top-3
                    after:h-0 after:w-0
                    after:-translate-x-1/2 after:border-8
                    after:border-t-main-color
                    after:border-l-transparent
                    after:border-b-transparent
                    after:border-r-transparent
                    after:opacity-0
                    after:transition-all

                    hover:before:opacity-100 hover:after:opacity-100
                `} data-tip={t('item5')}>
                    <NavLink to={'/whatwedo'} onClick={scrollToTop}>
                        <img src={negociacion} alt="Icono de Negociación" />
                    </NavLink>
                </div>
                {/* <p className={styles.pFive}>{t('item5')}</p> */}

                <div className={`${styles.six} ${styles.item}
                    relative
                    before:content-[attr(data-tip)]
                    before:absolute
                    before:px-2 before:py-2
                    before:left-1/2 before:-top-3
                    before:w-max before:max-w-xs
                    before:-translate-x-1/2 before:-translate-y-full
                    before:bg-main-color before:text-white
                    before:rounded-md before:opacity-0
                    before:transition-all

                    after:absolute
                    after:left-1/2 after:-top-3
                    after:h-0 after:w-0
                    after:-translate-x-1/2 after:border-8
                    after:border-t-main-color
                    after:border-l-transparent
                    after:border-b-transparent
                    after:border-r-transparent
                    after:opacity-0
                    after:transition-all

                    hover:before:opacity-100 hover:after:opacity-100
                `} data-tip={t('item6')}>
                    <NavLink to={'/whatwedo'} >
                        <img src={eco} alt="Icono de biomasa" />
                    </NavLink>
                </div>
                {/* <p className={styles.pSix}>{t('item6')}</p> */}

                <div className={`${styles.seven} ${styles.item3}
                    relative
                    before:content-[attr(data-tip)]
                    before:absolute
                    before:px-2 before:py-2
                    before:left-1/2 before:-top-3
                    before:w-max before:max-w-xs
                    before:-translate-x-1/2 before:-translate-y-full
                    before:bg-main-color before:text-white
                    before:rounded-md before:opacity-0
                    before:transition-all

                    after:absolute
                    after:left-1/2 after:-top-3
                    after:h-0 after:w-0
                    after:-translate-x-1/2 after:border-8
                    after:border-t-main-color
                    after:border-l-transparent
                    after:border-b-transparent
                    after:border-r-transparent
                    after:opacity-0
                    after:transition-all

                    hover:before:opacity-100 hover:after:opacity-100
                `} data-tip="M&A">
                    <Link to='/whatwedo' onClick={scrollToTop}>
                        <img src={relaciones} alt="Icono de Relaciones" />
                    </Link>
                    {/* <p>M&A</p> */}
                </div>
                
                <div className={styles.secondCircle}>
                    <img src={logo} alt="Logo de Fem Energía" />
                </div>
            </div>
            
        </section>
    );
}