import styles from "./Sidebar.module.css";
import {Link} from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { FaPowerOff } from "react-icons/fa6";


export default function Sidebar({onSectionChange}){

    const auth = useAuth();

    function handleLogout (){
        auth.logout();
    };

    return(
        <aside className={styles.sidebar}>
            <ul>
                <li>
                    <Link to={"#"} onClick={() => onSectionChange("news")}>Crear noticias</Link>
                </li>
                <li>
                    <Link to={"#"} onClick={() => onSectionChange("users")}>Usuarios</Link>
                </li>

            </ul>
            <button onClick={handleLogout}>
                <FaPowerOff className={styles.logoutButton}/>
            </button>
        </aside>
    )
}