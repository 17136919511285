import styles from "./Blog.module.css";
import { useState, useEffect } from "react";
import New from "../New/New";
import {API_URL} from "../auth/constants.ts";


export default function Blog () {
    const [items, setItems] = useState([]);

    async function loadNews(){
        try {
            const response = await fetch(`${API_URL}/new`, {
                
                headers:{
                    "Content-Type": "application/json",

                },
                
            });
            if(response.ok){
                const json = await response.json();
                setItems(json);
            }else{
                console.log("Error en la respuesta del servidor:", response.statusText);
            }
            // const data = await response.json();
            // setItems(data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(()=>{
        loadNews();
    }, []);
    return(
        <div>
            <ul className ={styles.blogContainer}>
                
                {items.length > 0 && items.map(({_id, title, date, description, url})=>(
                    
                    <New
                        key={_id}
                        _id={_id}
                        title={title}
                        date={date}
                        url={url}
                        description={description}
                    />
                ))}
            </ul>
        </div>
    );
}