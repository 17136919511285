import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import styles from "./Form.module.css";
import {useTranslation} from "react-i18next";

export default function Form(){
    const {t} = useTranslation(['form']);
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_kfebfwk', 'template_he2yqm3', form.current, 'lMj1W3EsRK8DhJs6V')
          .then((result) => {
              console.log(result.text);
              form.current.reset();
          }, (error) => {
              console.log(error.text);
          });
      };

    return(
        <>
            <form ref={form} onSubmit={sendEmail} className={styles.field}>
                <label htmlFor='user_name'>{t('name')}: </label>
                <input  id="user_name" type="text" name="user_name" placeholder={t('name')} required />
                <label htmlFor='user_email' >{t('email')}: </label>
                <input id='user_email' type="email" name="user_email" placeholder={t('email')} required />
                <label htmlFor='user_number'>{t('phone')}: </label>
                <input id='user_number' type="tel" name="user_number" placeholder={t('phone')} required />
                <label htmlFor="opciones">{t('option')}:</label>
                <select id="opciones" name="user_option" required>
                    <option value="opcion1">{t('option1')}</option>
                    <option value="Startups">{t('option2')}</option>
                    <option value="Tengo terrenos">{t('option3')}</option>
                    <option value="Biomasa">{t('option4')}</option>
                    <option value="Otra">{t('option5')}</option>
                </select>
                <label htmlFor='message'>{t('message')}: </label>
                <textarea id="message" name="message" placeholder={t('description')} required/>
                <input type="submit" value={t('button')} />
            </form>
        </>
    );
}