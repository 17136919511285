import React from "react";
import styles from "./Nav.module.css";
import {NavLink} from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../assets/logo.png"
import BurgerButton from "../BurguerButton/BurgerButton";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import {AiOutlineUserAdd} from "react-icons/ai"

export default function Nav (props) {
    const {t} = useTranslation(['nav']);
    const [clicked, setClicked]= useState(false);
    const [color, setColor] = useState(false);

    const changeLanguage = (lng) =>{
        i18n.changeLanguage(lng);
    }
    const changeColor = () =>{
        if(window.scrollY >= 80){
            setColor(true);
        }else{
            setColor(false);
        };
    };
    
    const handleClick = () =>{
        setClicked(prevClicked => !prevClicked);
    }

    useEffect(() => {
        window.addEventListener('scroll', changeColor);
        return () => {
            window.removeEventListener('scroll', changeColor);
        };
    }, []);
    
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 1000) {
                setClicked(false);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    },[]);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return(
            
        <div className={color ? `${styles.nav} ${styles.navColor2}`: `${styles.nav} ${styles.navColor1}` } >
                
            <div className = {styles.navimg}>
                <img src={logo} alt="Logo de empresa FEM Energia" />    
            </div>
                
            <div className = {clicked ? `${styles.active}`: `${styles.navitems}` }>
                <NavLink to={'/'}>
                    <span onClick={()=>{setClicked(false); scrollToTop()}} className = {styles.one}>{t('item')}</span>
                </NavLink>
                <NavLink to={'/whatwedo'}>
                    <span onClick={()=>{setClicked(false); scrollToTop()}} className = {styles.two}>{t('item2')}</span>
                </NavLink>
                <NavLink to={'/aboutus'}>
                    <span onClick={()=>{setClicked(false); scrollToTop()}} className = {styles.three}>{t('item3')}</span>
                </NavLink>
                <NavLink to={'/blog'}>
                    <span onClick={()=>{setClicked(false); scrollToTop()}} className={styles.six}>Blog</span>
                </NavLink>
                <NavLink to={'/contact'}>
                    <span onClick={()=>{setClicked(false); scrollToTop()}} className = {styles.four}>{t('item4')}</span>
                </NavLink>
                <NavLink to={'/workwithfem'}>
                    <span onClick={()=>{setClicked(false); scrollToTop()}} className = {styles.five}>{t('item5')}</span>
                </NavLink>
                <div className={styles.buttonContainer}>
                    <button className={styles.lngButton} onClick={()=>changeLanguage("en")}>EN</button>
                    <button className={styles.lngButton} onClick={()=>changeLanguage("es")}>ES</button>
                </div>
                <NavLink to={'/login'} className={styles.loginAnchor}>
                    <AiOutlineUserAdd className={styles.loginIcon}/>
                </NavLink>
            </div>

            <div className={styles.burger}>
                <BurgerButton clicked ={clicked} handleClick = {handleClick}/>
            </div>
        </div>
    );
};