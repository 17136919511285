import React, {useState, useEffect} from "react";
import { API_URL } from "../auth/constants.ts";
import { useAuth } from "../auth/AuthProvider.jsx";
import showAlert from "../functions.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { FaTrashAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdOutlineTitle } from "react-icons/md";
import { LuPlusCircle } from "react-icons/lu";
import { CiCalendar } from "react-icons/ci";
import { MdOutlineDescription } from "react-icons/md";
import { MdOutlineDataSaverOn } from "react-icons/md";
import { BiCodeCurly } from "react-icons/bi";




export default function News(){
    const [news, setNews] = useState([]);
    const [id, setId] = useState("");
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [description, setDescription] = useState("");
    const [operation, setOperation] = useState("");
    const [name, setName] = useState("");
    const [urls, setUrls] = useState("");

    const auth = useAuth();

    useEffect( () => {
        getNews();
    }, []);

    async function getNews (){
    
        try {
            const response = await fetch(`${API_URL}/new`);
            if(!response.ok){
                throw new Error('Failed to fetch users');
            }
            const data = await response.json();
            const usersArray = data
            setNews(usersArray);
        } catch (error) {
            console.error('Error fetching users: ', error);
        }
    }

    const openModal = (op, id, title, date, description, url ) =>{
        setTitle("");
        setDate("");
        setOperation(op);
        setDescription("");
        setUrls("");
        if(op === 1){
            setName('Crear nueva noticia');
        }else if(op === 2){
            setName('Editar noticia');
            setId(id);
            setTitle(title);
            setDate(date);
            setDescription(description);
            setUrls(url);
        }
        window.setTimeout(function(){
            document.getElementById('titulo').focus();
    
        }, 500);

    }
    const validar = () =>{
        let parametros;
        let metodo;
        let url;
        if(title.trim() === ''){
            showAlert('Escribe el título de la noticia', 'warning');
        }else if(date.trim() === ''){
            showAlert('Establece la fecha de la noticia', 'warning');
        }else if(description.trim() === ''){
            showAlert('Escribe la descripción de la noticia', 'warning');
        } else if(urls.trim() === ''){
            showAlert('Escribe la url de la imagen', 'warning');
        }else{
            if(operation === 1){
                parametros = {title: title.trim(), date:date.trim(), description: description.trim(), url:urls.trim()};
                metodo = 'POST';
                url = `${API_URL}/dashboard`;
            }else{
                parametros = {id:id, title:title.trim(), date:date.trim(), description:description.trim(),url:urls.trim()};
                metodo = 'PUT';
                url = `${API_URL}/news/${parametros.id}`;
                console.log(parametros.id);
            }
            enviarSolicitud(metodo, parametros, url);
        }
    }
    const enviarSolicitud = async (metodo, parametros, url) =>{
        try {
            const response = await fetch(url, {
                method: metodo,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.getAccessToken()}`,
                },
                body: JSON.stringify(
                    parametros
                ),

            });

            if(!response.ok){
                throw new Error('Network response was not ok');
            }
            
            if(response.ok && operation === 1){
                showAlert("Noticia creada correctamente", "success");
                getNews();
            }else if(response.ok && operation === 2){
                showAlert("Noticia actualizada correctamente", "success");
                getNews();
            }else{
                console.log("Algo salió mal");
            }
            
        } catch (error) {
            showAlert('Error en la solicitud', 'error');
            console.log(error);
        }
    }
    const handleClickDelete = async (id) => {
        try {
            const noticiaId = id;
            const response = await fetch(`${API_URL}/news/${noticiaId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
            });
            if(response.ok){
                const data = await response.json();
                showAlert('Noticia Eliminada con éxito', "success");
                getNews();
            }
        } catch (error) {
            showAlert('Error al eliminar la noticia', "error");
        }
    }
    
    return(
        <section className="w-100 m-auto mt-0">
            <div className="container d-flex flex-column align-items-center justify-content-center w-100">
                <div className="row mt-3 w-100">
                    <div className="col-md-4 offset-4">
                        <div className="d-grid mx-auto">
                            <button onClick={()=>openModal(1)} className="btn btn-dark d-flex justify-content-center align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#modalUsers">
                                <LuPlusCircle className="fs-5"/> Añadir
                            </button>
                        </div>

                    </div>
                </div>
                <div className="row mt-3 w-100 d-flex align-items-center justify-content-center">
                    <div className="w-100">
                        <div className="table-responsive-sm overflow-auto">
                            <table className="table table-bordered vw-75">
                                <thead>
                                    <tr><th>#</th><th>Título</th><th>Fecha</th><th>Descripción</th><th>Url</th></tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {Array.isArray(news) && news.map((item, i)=>(
                                        
                                        <tr key={item._id}>
                                            <td>{(i+1)}</td>
                                            <td>{item.title}</td>
                                            <td>{item.date}</td>
                                            <td>{item.description}</td>
                                            <td>
                                                <img className="img-fluid w-100" src={item.url} alt="Imágenes Alusivas a las noticias" />
                                                
                                            </td>
                                            <td>
                                                <button onClick={()=> openModal(2, item._id, item.title, item.date, item.description, item.url)} className="btn btn-warning" data-bs-toggle='modal' data-bs-target='#modalUsers'>
                                                    <FaEdit />
                                                </button>
                                                &nbsp;
                                                <button className="btn btn-danger" onClick={() => handleClickDelete(item._id) }>
                                                    <FaTrashAlt />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="modalUsers" className="modal fade" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <label className="h5">{name}</label>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input type="hidden" id="id"/>
                            <div className="input-group mb-3">
                                <span className="input-group-text"><MdOutlineTitle /></span>
                                <input type="text" id="titulo" className="form-control" placeholder="Título" value={title} onChange={(e)=> setTitle(e.target.value)} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text"><CiCalendar/></span>
                                <input type="date" id="fecha" className="form-control" placeholder="Fecha" value={date} onChange={(e)=> setDate(e.target.value)} />
                            </div>
                            
                
                            <div className="input-group mb-3">
                                <span className="input-group-text"><MdOutlineDescription /></span>
                                <textarea type="text" id="descripcion" className="form-control" placeholder="Descripción" value={description} onChange={(e)=> setDescription(e.target.value)} />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text"><BiCodeCurly/></span>
                                <input type="url" id="url" className="form-control" placeholder="Url" value={urls} onChange={(e)=> setUrls(e.target.value)} />
                            </div>
                            
                            
                            <div className="d-grid col-6 mx-auto">
                                <button onClick={()=>validar()} className="btn btn-success  d-flex justify-content-center align-items-center gap-2">
                                    <MdOutlineDataSaverOn className="fs-5" /> Crear
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}