import styles from "./Cards.module.css";
import terrenos from "../assets/terreno.jpg";
import desarrollo from "../assets/desarrollo.jpg";
import startups from "../assets/startups.jpg";
import biomasa from "../assets/biomasa.jpg";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Cards () {
    const {t} = useTranslation(['cards']);
    return(
        <section className={styles.cardsContainer}>

            <div className={styles.cardOne}>
                <div className={styles.content}>
                    <img src={terrenos} alt="Imagen de terrenos" />
                    <h2>{t('title')}</h2>
                    <div className={styles.cardContent}>
                        <h3>{t('title')}</h3>
                        <p>{t('description3')}</p>
                        <Link to={"/contact"}>
                            <button type="button">{t('button')}</button>
                        </Link>
                        
                    </div>
                </div>
            </div>

            <div className={styles.cardTwo}>
                <div className={styles.content}>
                    <img src={desarrollo} alt="Imagen de desarrollo" />
                    <h2>{t('title2')}</h2>
                    <div className={styles.cardContent}>
                        <h3>{t('title2')}</h3>
                        <p>{t('title2')}</p>
                        <Link to={"/contact"}>
                            <button type="button">{t('button')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className={styles.cardThree}>
                <div className={styles.content}>
                    <img src={startups} alt="Imagen de equipo de trabajo" />
                    <h2>{t('title3')}</h2>
                    <div className={styles.cardContent}>
                        <h3>{t('title3')}</h3>
                        <p>{t('description2')}</p>
                        <Link to={"/contact"}>
                            <button type="button">{t('button')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className={styles.cardFour}>
                <div className={styles.content}>
                    <img src={biomasa} alt="Imagen de tractor en campo" />
                    <h2>{t('title4')}</h2>
                    <div className={styles.cardContent}>
                        <h3>{t('title4')}</h3>
                        <p>{t('description')}</p>
                        <Link to={"/contact"}>
                            <button type="button">{t('button')}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}