import React from "react";
import styles from "./AboutUs.module.css";
import Planta from "../assets/plantahidrogeno.jpeg";
import AboutImg2 from "../assets/aboutimg2.jpg";
import { Link } from "react-router-dom";
import Timeline from "../Timeline/Timeline";
import {useTranslation} from "react-i18next"


export default function AboutUs() {
    const {t} = useTranslation(["aboutUs"]);
    return (
        <div>
            <section className={styles.aboutUsSection}>
                
            </section>

            <section className={styles.aboutUsTitleContainer}>
                <h1 className={styles.aboutUsTitle}>{t('title')}</h1>
                <p>{t('description')} <span>{t('name')}.</span> {t('description2')}<br/> {t('description3')} <span>{t('name')}</span>, {t('description4')}</p>
               
                <Timeline/>
                <div className={styles.aboutitem1}>
                    
                    <img src={Planta} alt="Imagen alusiva a trabajadores de paneles solares" />
                    <div>
                        <h2>{t('title2')}</h2>
                        <p>{t('description5')} <br/>{t('description6')}</p>
                    </div>
                </div>
                <div className={styles.aboutitem2}>
                    
                    <img src={AboutImg2} alt="Imagen alusiva a trabajadores de paneles solares" />
                    <div>
                        <h2>{t('title3')}</h2>
                        <p>{t('description7')}</p>
                    </div>
                </div>
            </section>
            <div className={styles.aboutContact}>
                <p>{t('title4')}<span>{t('name')}</span> {t('title5')}</p>
                <Link to={"/contact"}>
                    <button type="button">{t('button')}</button>
                </Link>
            </div>
        </div>
    )
    
};