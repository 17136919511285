import React from "react";
import styles from "./WorkWithUs.module.css";
import ingenieria from "../assets/Ingenieros.jpg";
import legal from "../assets/legal.jpg";
import negocios from "../assets/negocios.jpg";
import hidrogeno from "../assets/hidrogeno.jpg";
import trainees from "../assets/trinees.jpg";
import practicantes from  "../assets/practicante.jpg";
import comercial from "../assets/comercial.jpg";
import sena from "../assets/sena.jpg"; 
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function WorkWithUs () {
    const {t} = useTranslation(['workwithus']);
    return (
        <>
            <section className={styles.workImage}>
            </section>   
            <h1 className={styles.workTitle}>{t('title')}</h1>

            <section className={styles.itemsContainer}>
                <article className={styles.items}>
                    <img src={ingenieria} alt="Foto de ingeniero" />
                    <h2>{t('item')}</h2>
                    <p>{t('description')}</p>
                </article>
                <article className={styles.items}>
                    <img src={legal} alt="" />
                    <h2>{t('item2')}</h2>
                    <p>{t('description2')}</p>
                </article>
                <article className={styles.items}>
                    <img src={negocios} alt="" />
                    <h2>{t('item3')}</h2>
                    <p>{t('description3')}</p>
                </article>
                <article className={styles.items}>
                    <img src={hidrogeno} alt="" />
                    <h2>{t('item4')}</h2>
                    <p>{t('description4')}</p>
                </article>
                <article className={styles.items}>
                    <img src={trainees} alt="" />
                    <h2>{t('item5')}</h2>
                    <p>{t('description5')}</p>
                </article>
                <article className={styles.items}>
                    <img src={practicantes} alt="" />
                    <h2>{t('item6')}</h2>
                    <p>{t('description6')}</p>
                </article>
                <article className={styles.items}>
                    <img src={comercial} alt="" />
                    <h2>{t('item7')}</h2>
                    <p>{t('description7')}</p>
                </article>
                <article className={styles.items}>
                    <img src={sena} alt="" />
                    <h2>{t('item8')}</h2>
                    <p>{t('description8')}</p>
                </article>
            </section>
            <div className={styles.buttonSection}>
                <p>{t('title2')}</p>
                <Link to={"https://www.linkedin.com/company/fem-energ%C3%ADa/"} target="_blank">
                    <button className={styles.buttonLink} type="button" >{t('button')}</button>
                </Link>
            </div>
            
        </>
    )
};