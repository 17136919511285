import { useContext, createContext, useState, useEffect} from "react";
import {API_URL} from "./constants.ts";
import styles from "./AuthProvider.module.css";


const AuthContext = createContext({
    isAuthenticated: false,
    getAccessToken: () => {}, 
    saveUser: (userData)=>{},
    getRefreshToken: () =>{},
    getUser: () => ({}),
    logout: () => {}

});
export function AuthProvider({children}){
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    async function requestNewAccessToken(refreshToken){
        try {
            const response = await fetch(`${API_URL}/refresh-token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${refreshToken}`
                },
            });
            
            if(response.ok){
                const json = await response.json();

                if(json.error){
                    throw new Error(json.error);
                }
                return json.body.accessToken;
            }else{
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getUserInfo(accessToken){
        try {
            const response = await fetch(`${API_URL}/user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
            });

            if(response.ok){
                const json = await response.json();

                if(json.error){
                    throw new Error(json.error);
                }
                return json.body;
            }else{
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function checkAuth(){
        if(accessToken){
            const userInfo = await getUserInfo(accessToken);
            if(userInfo){
                saveSessionInfo(userInfo, accessToken, getRefreshToken());
                setIsLoading(false);
                return;
            }
        }else{
            const token = getRefreshToken();
            if(token){
                const newAccessToken = await requestNewAccessToken(token);

                if(newAccessToken){
                    const userInfo = await getUserInfo(newAccessToken);
                    if(userInfo){
                        saveSessionInfo(userInfo, newAccessToken, token);
                        setIsLoading(false);
                        return;
                    }
                }
            }
        }
        setIsLoading(false);
    }

    function saveSessionInfo(userInfo, accessToken, refreshToken){
        setAccessToken(accessToken);

        localStorage.setItem("token", JSON.stringify(refreshToken));
        localStorage.setItem("user", JSON.stringify({user: userInfo.user, permissions: userInfo.permissions}));
        setIsAuthenticated(true);
        setUser(userInfo);
    }

    function getAccessToken(){
        return accessToken;
    }

    function getRefreshToken(){
        const tokenData = localStorage.getItem("token");
        
        if(tokenData){
            const token = JSON.parse(tokenData);
            return token; 
        }
        return null;
    }

    function saveUser(userData){
        saveSessionInfo(userData.body.user, userData.body.accessToken, userData.body.refreshToken);
    }

    function getUser(){
        return user;
    }

    useEffect(() =>{
        const storedToken = localStorage.getItem("token");
        const storedPermissions = localStorage.getItem("user");
        if(storedToken){
            setAccessToken(storedToken);
            setIsAuthenticated(true);
            if(storedPermissions){
                const userInfo = JSON.parse(storedPermissions);
                setUser(userInfo);
            }
        }else{
            checkAuth();
        }
        
        setIsLoading(false);
    }, []);

    function logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setIsAuthenticated(false);
        setAccessToken("");
        setUser({});
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, getAccessToken, saveUser, getRefreshToken, getUser, logout }}>
            {isLoading ? <div className={styles.skcubegrid}>
          <div className={`${styles.skcube} ${styles.skcube1}`}></div>
          <div className={`${styles.skcube} ${styles.skcube2}`}></div>
          <div className={`${styles.skcube} ${styles.skcube3}`}></div>
          <div className={`${styles.skcube} ${styles.skcube4}`}></div>
          <div className={`${styles.skcube} ${styles.skcube5}`}></div>
          <div className={`${styles.skcube} ${styles.skcube6}`}></div>
          <div className={`${styles.skcube} ${styles.skcube7}`}></div>
          <div className={`${styles.skcube} ${styles.skcube8}`}></div>
          <div className={`${styles.skcube} ${styles.skcube9}`}></div>
        </div> : children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);
