import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

// Imagenes
import Mail from "../assets/mail.svg";
import SmartPhone from "../assets/smartphone.svg";
import Location from "../assets/location.svg";
import Linkedin from "../assets/inkedin.svg";
import Whatsapp from "../assets/whatsapp.svg";


export default function Footer () {
    const {t} = useTranslation(['footer']);
    return(
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <h2 className={styles.footerTitle}>{t('title')}</h2>
                <div className={styles.infoContainer}>
                    <div className={styles.contactContainer}>
                        <div className={styles.contact}>
                            <div className={styles.contactItem}>
                                <img src={Location} alt="Icono de locación" />
                                <p>Bogotá D.C., Colombia</p>
                            </div>

                            <div className={styles.contactItem}>
                                <img src={SmartPhone} alt="Icono de celular" />
                                <p>310 760 2675</p>
                            </div>

                            <div className={styles.contactItem}>
                                <img src={Mail} alt="Icono de email" />
                                <p>correspondencia@femenergia com</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className={styles.socialMediaContainer}>
                        <Link to={"https://www.linkedin.com/company/fem-energ%C3%ADa/"} target="_blank">
                            <img src={Linkedin} alt="Icono Linkedin" />
                        </Link>
                        <Link to={"https://wa.me/573107602675" } target="_blank">
                            <img src={Whatsapp} alt="Icono Whatsapp" />
                        </Link>
                        
                    </div>
                </div>
            </div>
            
        </footer>
    )
};