import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {API_URL} from "../auth/constants.ts";
import styles from "./SignUp.module.css";
import { toast } from "sonner";
import { Link } from "react-router-dom";

export default function SignUp(){
    const[user, setUser] = useState("");
    const[email, setEmail] = useState("");
    const[password, setPassword] = useState("");
    const[errorResponse, setErrorResponse] = useState("");

    const goTo = useNavigate();

    async function handleSubmit(e){  
        e.preventDefault();
        if(!user || !email || !password ){
            return toast.error("Los campos son obligatorios");
        }

        try {
            const response = await fetch(`${API_URL}/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user,
                    email,
                    password
                }),

            });

            if(response.ok){
                console.log("User created successfully");
                setErrorResponse("");
                goTo("/login");
            }else{
                console.log("Something went wrong");
                const jsonData = await response.json();
                setErrorResponse(jsonData.body.error);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return(

        <section className={styles.formContainer}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <h1 className={styles.formTitle}>Crear Usuario</h1>
                {!!errorResponse && <div className={styles.error}>{errorResponse}</div>}
                <label>Usuario</label>
                <input type="text" value={user} onChange={(e) => setUser(e.target.value)}/>

                
                <label>Correo</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>

                
                <label>Contraseña</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>

                <p className={styles.ifNotSignup}>
                    ¿Ya tienes una cuenta? 
                    <Link to={"/login"}> Inicia Sesión</Link>

                </p>
                
                <button>Registrar</button>

               
                
                
            </form>
        </section>
        
    )
}