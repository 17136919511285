import styles from "./WhatWeDoItems.module.css";
import energiarenovable from "../assets/energia-renovable.gif";
import negociaciones from "../assets/negociaciones.gif";
import industria from "../assets/industria.gif";
import ayuda from "../assets/ayuda.gif";
import biomasa from "../assets/biomasa.gif";
import alianza from "../assets/alianza.gif";
import desarrollo from "../assets/desarrollo.gif";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function WhatWeDoItems () {
    const {t} = useTranslation(['whatwedoitems']);
    return(
        <>
            <div id="1" className={styles.itemOne}>
                <div className={styles.itemsCard}>  
                    <img src={desarrollo} alt="Gif alusivo al desarrollos" />
                    <h2>{t('item')}</h2>
                    <p>{t('description')}</p>
                </div>
            </div>
            
            <div className={styles.mainItems}>

                    <div id="biomasa" className={styles.itemsCard}> 
                        <img src={biomasa} alt="Gif alusivo a la biomasa" />
                        <h2>{t('item6')} </h2>
                        <p>{t('description1')}</p>
                        <Link to={"/contact"}>
                            <button type="button">{t('button')}</button>
                        </Link>
                    </div>

                    <div id="proyectosrenovables" className={styles.itemsCard}>
                        <img src={energiarenovable} alt="Imagen de batería amigable con medio ambiente" />
                        <h2>{t('item2')} </h2>
                        <p>{t('description2')}</p>
                        <Link to={"/contact"}>
                            <button type="button">{t('button')}</button>
                        </Link>
                    </div>
                    
                    <div id="solucionesindustriales" className={styles.itemsCard}> 
                        <img src={industria} alt="Gif alusivo a energía renovable para industrias" />
                        <h2>{t('item3')}</h2>
                        <p>{t('description3')}</p>
                        <Link to={"/contact"}>
                            <button type="button">{t('button')}</button>
                        </Link>
                    </div>

                    <div id="contructoralianzas" className={styles.itemsCard}> 
                        <img src={alianza} alt="Gif de dos manos chocando puño" />
                        <h2>{t('item4')} </h2>
                        <p>{t('description4')}</p>
                    </div>
                    
                    <div id="negociacionesofftake" className={styles.itemsCard}> 
                        <img src={negociaciones} alt="Imagen de negociación" />
                        <h2>{t('item5')}</h2>
                        <p>{t('description5')}</p>
                    </div>
                    
                    <div id="m&A" className={styles.itemsCard}>
                        <img src={ayuda} alt="Gif de mano que hace alusión a ayudar" />
                        <h2>M&A</h2>
                        <p>{t('description6')}</p> 
                    </div>
                    
                    
            </div>
        </>
    );
};