import "./App.css";
import Contact from "./components/Contact/Contact";
import AboutUs from "./components/AboutUs/AboutUs";
import MainPage from "./components/MainPage/MainPage";
import Nav from "./components/Nav/Nav";
import { Routes, Route} from "react-router-dom";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import Footer from "./components/Footer/Footer";
import WorkWithUs from "./components/WorkWithUs/WorkWithUs";
import NewDetail from "./components/NewDetail/NewDetail";
import { Suspense } from "react";
import BlogSection from "./components/BlogSection/BlogSection";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import DashBoard from "./components/DashBoard/DashBoard";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import { AuthProvider } from "./components/auth/AuthProvider";



function App() {
  
  return (
    <div className="App" style={{padding: '0px'}}>
      <Suspense fallback={
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      }>
        <Nav/>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/whatwedo" element={<WhatWeDo/>}/>
            <Route path="/aboutus" element={<AboutUs/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/workwithfem" element={<WorkWithUs/>}/>
            <Route path= "/detail/:id" element={<NewDetail/>}/>
            <Route path="/blog" element={<BlogSection/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element ={<SignUp/>}/>
            <Route path="/" element={<ProtectedRoute/>}>
              <Route path="/dashboard" element={<DashBoard/>}/>
            </Route>
          </Routes>
        </AuthProvider>
        
        <Footer/>
      </Suspense>
      
    </div>
  );
}

export default App;
