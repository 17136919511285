import styles from "./BurgerButton.module.css";

export default function BurgerButton (props){
    return(
        <>
            <div onClick={props.handleClick} className={props.clicked ? `${styles.navIcon} ${styles.open}`: `${styles.navIcon}` }>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </>
    );

}