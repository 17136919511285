import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { useState } from "react";
import styles from "./Dashboard.module.css";
import Sidebar from "../Sidebar/Sidebar";
import News from "../pages/News";
import Users from "../pages/Users";
// import SignUp from "../SignUp/SignUp"

export default function DashBoard (){
    const [currentSection, setCurrentSection] = useState("news");
    const {getUser} = useAuth();

    function handleSectionChange (section){
        setCurrentSection(section);
    };

    
    const user = getUser();

  // Verifica que 'user' no sea undefined y que 'permissions' exista antes de usar 'includes'
    const isAdmin = user && user.permissions && user.permissions.includes('isAdmin');
    return isAdmin?(
        <>
            <section className={styles.dashBoardContainer}>
                <div className={styles.dashBoardContent}>
                    <Sidebar onSectionChange={handleSectionChange}/>

                    <article className={styles.dashBoardItems}>
                        
                        <h1 className={styles.dashBoardTitle}>DashBoard de {user.user}</h1>
                        {currentSection === "news" && <News/> }
                        {currentSection === "users" && <Users/> }
                    </article>
                </div>

            </section>
        </>
        
        
    ) :(
        <Navigate to={"/"}/>
    )
}