import React from "react";
import styles from "./Contact.module.css";
import Form from "../Form/Form";
import contacto from "../assets/imagenContact.jpg";
import {useTranslation} from "react-i18next";

export default function Contact () {
    const {t} = useTranslation(['contact']);
    return (
        <div>
            <section className={styles.contactSection}>
            </section>
            <section className={styles.contactContainer}>
                <h2>{t('title')}</h2>
                <div className={styles.formContainer}>
                    <img src={contacto} alt="Grupo de personas trabajando" />
                    <Form/>
                </div>
            </section>
            
        </div>
    )
};