// import styles from "./NewDetail.module.css";
// import { useParams } from "react-router-dom";
// import data from "../data.json";
// export default function NewDetail (){
//     const {id} = useParams();
    
//     const newsItem = data.find((item) => item.id ===parseInt(id));
//     if(!newsItem){
//         return <div> No se encontró la noticia </div>
//     }
    

//     return(
//         <div className={styles.detailContainer}>
//             <div className={styles.detailInfo}>    
//                 <img src={newsItem.url} alt="Imagen de todos los participantes" />
//                 <h1>{newsItem.title}</h1>
//                 <p className={styles.date}>{newsItem.date}</p>
//                 <p className={styles.description}>{newsItem.description}</p>
//             </div>
//         </div>
//     )
// }
import styles from "./NewDetail.module.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {API_URL} from "../auth/constants.ts";

export default function NewDetail() {
    const { id } = useParams();
    const [newsItem, setNewsItem] = useState(null);

    useEffect(() => {
        // Hacer una solicitud GET al backend para obtener detalles de la noticia con el ID especificado
        fetch(`${API_URL}/news/${id}`) // Asegúrate de especificar la ruta correcta
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("No se encontró la noticia");
                }
            })
            .then((data) => {
                setNewsItem(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    if (!newsItem) {
        return <div> No se encontró la noticia </div>;
    }

    return (
        <div className={styles.detailContainer}>
            <div className={styles.detailInfo}>
                <img src={newsItem.url} alt="Imagen de todos los participantes" />
                <h1>{newsItem.title}</h1>
                <p className={styles.date}>{newsItem.date}</p>
                <p className={styles.description}>{newsItem.description}</p>
            </div>
        </div>
    );
}
