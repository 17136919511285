// import React, { useState, useEffect } from 'react';
import styles from "./Timeline.module.css";
import { useTranslation } from 'react-i18next';
import hidrogeno from "../assets/timeline-item2.jpeg";
import hidrogeno2 from "../assets/timeline-item7.jpg";
import planta from "../assets/timeline-item4.jpeg";
import turbinas from "../assets/timeline-item5.jpeg";
import hidrogeno3 from "../assets/timeline-item6.jpeg";
import logo from "../assets/logo.png";
import plantaHidrogeno from "../assets/timeline-item8.png";


const Timeline = () => {
  const {t} = useTranslation(['timeline']); 

  return (
    
    <div className={styles.main}>
      <div className={styles.timeline}>        
        <div className={styles.box}>
          <div className={styles.container}>
            
            <div className={`${styles.lines} ${styles.cards}`}>
              <div className={styles.item}>
                <img className={styles.dot} src={hidrogeno3} alt="Imagen de turbinas eólicas" />
                <div className={styles.line}></div>
                <div className={styles.card}>
                  <h4>1997</h4>
                  <li>{t('item')}</li>
                  <li>{t('item2')}</li>
                </div>
              </div>
              <div className={styles.item}>
                <img className={`${styles.imgDoing} ${styles.dot}`} src={hidrogeno} alt="Imagen de turbinas eólicas" />
                <div className={styles.line}></div>
                <div className={styles.card}>
                  <h4>2005</h4>
                  <li>{t('item3')}</li>
                  <li>{t('item4')}</li>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.dot} src={hidrogeno2} alt="Imagen de turbinas eólicas" />
                <div className={styles.line}></div>
                <div className={styles.card}>
                  <h4>2013</h4>
                  <li>{t('item5')}</li>
                  <li>{t('item6')}</li>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.dot} src={plantaHidrogeno} alt="Imagen de turbinas eólicas" />
                <div className={styles.line}></div>
                <div className={styles.card}>
                  <h4>2020</h4>
                  <li>{t('item7')}</li>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.dot} src={turbinas} alt="Imagen de turbinas eólicas" />
                <div className={styles.line}></div>
                <div className={styles.card}>
                  <h4>2022</h4>
                  <li>{t('item8')}</li>
                  <li>{t('item9')}</li>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.dot} src={logo} alt="Imagen de turbinas eólicas" />
                <div className={styles.line}></div>
                <div className={styles.card}>
                  <h4>2023</h4>
                  <li>{t('item10')}</li>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.dot} src={planta} alt="Imagen de turbinas eólicas" />
                <div className={styles.line}></div>
                <div className={styles.card}>
                  <h4>2030</h4>
                  <li>{t('item11')}</li>
                </div>
              </div>             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
