import styles from "../BlogSection/BlogSection.module.css";
import Blog from "../Blog/Blog";

export default function BlogSection (){
    return(
        <section className={styles.blogSection}>
            <h1 className={styles.blogTitle}>Blog</h1>
            <Blog/>
        </section>
    )
}