import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "./config/i18next.config";
import { AuthProvider } from './components/auth/AuthProvider';
import { Toaster } from 'sonner';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <BrowserRouter>
      <Toaster/>
      <App />
    </BrowserRouter>
  </AuthProvider>
 
);

