import WhatWeDoItems from "../WhatWeDoItems/WhatWeDoItems";
import styles from "./WhatWeDo.module.css";
import { useTranslation } from "react-i18next";


export default function WhatWeDo (){
    const {t} = useTranslation(['whatwedo']);
    return(
        <div>
            <section className={styles.mainImage}>
                <h1>{t('title')}</h1>
            </section>
            <section>
                <WhatWeDoItems/>
            </section>
        </div>
    );
};