import styles from "./New.module.css";
import { Link } from "react-router-dom";

export default function New ({_id, title, date, description, url}){
    return(
        <div className={styles.newsContainer}>
            <div className={styles.imgContainer}>
                <img src={url} alt="Imagen de los participantes"/>
            </div>
            <div className={styles.newsInfo}>
                <h4>{title}</h4>
                <p>{date}</p>
            </div>
            <Link to={`/detail/${_id}`}>
                <button className={styles.newsButton}>Leer más</button>
            </Link>

        </div>
    )
}