import { useState } from "react";
import { useAuth} from "../auth/AuthProvider";
import { Navigate, useNavigate} from "react-router-dom";
import {API_URL} from "../auth/constants.ts";
import styles from "./Login.module.css";
import { toast } from "sonner";
import { Link } from "react-router-dom";

export default function Login (){
    const [user, setUser]= useState("");
    const [password, setPassword]= useState("");
    const [errorResponse, setErrorResponse] = useState("");
    
    const auth = useAuth();
    const goTo = useNavigate();
    async function handleSubmit(e){  
        e.preventDefault();
        if(!user || !password){
            return toast.error("Los campos son obligatorios");
        }
        try {
            const response = await fetch(`${API_URL}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user,
                    password
                }),
                
            });

            if(response.ok){
                console.log("Login successfully");
                setErrorResponse("");
                const json = await response.json();
                if(json.body.accessToken && json.body.refreshToken){
                    auth.saveUser(json);
                    goTo("/dashboard");
                }
                
            }else{
                console.log("Something went wrong");
                const jsonData = await response.json();
                setErrorResponse(jsonData.body.error);
            }
        } catch (error) {
            console.log(error);
        }
    };

    if(auth.isAuthenticated){
        return <Navigate to="/dashboard"/>
    }
    return (
        <section className={styles.formContainer}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <h1 className={styles.formTitle}>Login</h1>
                {!!errorResponse && <div className={styles.error}>{errorResponse}</div>}

                <label>Usuario </label>
                <input type="text"  value={user} onChange={(e)=> setUser(e.target.value) }/>

                
                <label>Contraseña:</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                
                <p className={styles.ifNotSignup}>
                    ¿No tienes una cuenta?
                    <Link to={"/signup"}> Resgistrate</Link>

                </p>

                <button>Ingresa</button>

                

                
            </form>
        </section>
        
    )
}