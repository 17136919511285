// Dependencias de react
import React from "react";
import { useState, useEffect } from "react";

// Estilos 
import styles from "./MainPage.module.css";

// Componentes
import Cards from "../Cards/Cards";
import Items from "../Items/Items";
import Blog from "../Blog/Blog";

// Imagenes
import Map from "../assets/world.svg";
import ColombiaMap from "../assets/colombiaMap.svg";
import Metanol from "../assets/combustible-ecologico.gif";
import Eolico from "../assets/turbina-eolica.gif";
import Tierra from "../assets/tierra.gif";
import Industriales from "../assets/industria.gif";
import {useTranslation} from "react-i18next";

export default function MainPage () {
    const {t} = useTranslation(["global"]);
    const [map, setMap] = useState(false);
    const [colombiaMap, setColombiaMap] = useState(true);
    const img1 = "https://drive.google.com/file/d/10fMGMud6gVDardFiwdGs3j6MXizbQJaW/view?usp=drive_link";

    function mapChange () {
        setMap(true);
        setColombiaMap(false);
    };

    function colombiaMapChange(){
        setMap(false);
        setColombiaMap(true);
        console.log('activo');
    }
   
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    const [count3, setCount3] =useState(0);
    const [count4, setCount4] =useState(0);
    useEffect(() => {
        const handleScroll = () => {
            // Obtener la posición y altura de la sección objetivo
            const section = document.querySelector('.map');
            if (section) {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
      
              // Obtener la posición actual del scroll
                const scrollPosition = window.scrollY;
      
              // Calcular el punto donde queremos activar el contador
                const triggerPoint = sectionTop - window.innerHeight + 100;
      
              // Activar el contador si alcanzamos el punto de activación
                if (scrollPosition >= triggerPoint && scrollPosition <= sectionTop + sectionHeight) {
                    startCounter();
                    startCounter2();
                    startCounter3();
                    startCounter4();
                };
            };
        };
      
        const startCounter = () => {
            if (count < 1000) {
              // Incrementar el contador cada 10ms hasta llegar a 1000
                const time = setInterval(() => {
                    setCount((prevCount) => Math.min(prevCount + 1, 1000));
                }, 10);
      
              // Detener el contador cuando lleguemos a 1000
                return () => clearInterval(time);
            }
        };
        const startCounter2 = () => {
            if (count2 < 500) {
              // Incrementar el contador cada 10ms hasta llegar a 500
                const time = setInterval(() => {
                    setCount2((prevCount) => Math.min(prevCount + 1, 500));
                }, 20);
      
              // Detener el contador cuando lleguemos a 500
                return () => clearInterval(time);
            }
        };

        const startCounter3 = () => {
            if (count3 < 60000) {
              // Incrementar el contador cada 10ms hasta llegar a 500
                const time = setInterval(() => {
                    setCount3((prevCount) => Math.min(prevCount + 100, 60000));
                }, 10);
      
              // Detener el contador cuando lleguemos a 500
                return () => clearInterval(time);
            }
        };
        const startCounter4 = () => {
            if (count4 < 10) {
              // Incrementar el contador cada 10ms hasta llegar a 500
                const time = setInterval(() => {
                    setCount4((prevCount) => Math.min(prevCount + 1, 10));
                }, 1000);
      
              // Detener el contador cuando lleguemos a 500
                return () => clearInterval(time);
            }
        };
      
        window.addEventListener('scroll', handleScroll);
      
          // Limpieza del evento cuando el componente se desmonte
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [count, count2, count3,count4]);


    return (
        <main className={styles.principalPageContainer}>
            <section className={styles.principalImgContainer}>
                <h1 className={styles.principalTitle}>{t('title')}</h1>
            </section>

            <section className={styles.infoContainer}>
                <article className={styles.aboutFem}>
                    <h3 className={styles.aboutFemTitle}><span>{t('name')}</span> {t('title2')}</h3>
                    <p>{t('description')}
                     </p>
                </article>

                <article className={styles.mainObjectives}>
                    <div className={styles.mainObjectivesContainer}>
                        <h2 className={styles.mainObjectivesTitle}>{t('title3')} <span>{t('name')}</span></h2>
                        <Items/>
                    </div>
                    
                </article>

                <article className={styles.map}>
                    <div className={styles.mapContainer}>
                        <h2><span>{t('name')}</span> {t('title4')} </h2>
                        <div className={styles.mapDataContainer}>
                            <div className={styles.worldMap}>
                                <img className={map ? `${styles.worldMapImage}`: `${styles.worldMapImage2}`} onClick={mapChange} src={Map} alt="Mapa del mundo" />
                                <img className={colombiaMap ? `${styles.colombiaMap}`: `${styles.colombiaMap2}`} onClick={colombiaMapChange} src={ColombiaMap}  alt="Mapa de Colombia" />
                            </div>
                            <div className="map"></div>  
                            <div className={styles.dataContainer}>
                                <div className={styles.dataItem}>
                                    <img src={Metanol} alt="Gif alusivo a Metanol" />
                                    <h3>{count}<span> MW</span> </h3>
                                    <p>{t('item')}</p>
                                </div>
                                <div className={styles.dataItem}>
                                    <img src={Eolico} alt="Gif alusivo a energía eólica" />
                                    <h3>{count2}<span> MW</span> </h3>
                                    <p>{t('item2')}</p>
                                </div>
                                <div className={styles.dataItem}>
                                    <img src={Industriales} alt="Gif alusivo a la industria" />
                                    <h3>{count4} <span>MW</span></h3>
                                    <p>{t('item3')}</p>
                                </div>
                                <div className={styles.dataItem}>
                                    <img src={Tierra} alt="Gif alusivo a la tierra" />
                                    <h3>{count3} <span>{t('item4-1')}</span></h3>
                                    <p>{t('item4')}</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </article>

            </section>
            <section className={styles.entrepreneursContainer}>
                <div className={styles.entrepreneurs}>
                    <div className={styles.entrepreneursTitles}>
                        <h4>{t('title5')}</h4>
                        <h2>{t('title6')}</h2>
                    </div>
                    <Cards/>
                </div>  
            </section>
            <section className = {styles.newsContainer}>
                <h2 className={styles.blogTitle}>{t('title7')}</h2>
                <Blog/>
            </section>
        </main>
    )
};