import React, {useState, useEffect} from "react";
import { API_URL } from "../auth/constants.ts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import showAlert from "../functions.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { FaTrashAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { LuPlusCircle } from "react-icons/lu";
import { MdEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa6";
import { MdOutlineDataSaverOn } from "react-icons/md";



export default function Users(){
    const [users, setUsers] = useState([]);
    const [id, setId] = useState("");
    const [user, setUser] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [email, setEmail] = useState("");
    const [operation, setOperation] = useState("");
    const [title, setTittle] = useState("");
    const [password, setPassword] = useState("");
    const [availablePermissions] = useState(["base", "isAdmin"]);

    const handlePermissionChange = (permission) => {
        if (permissions.includes(permission)) {
            setPermissions(permissions.filter(p => p !== permission));
        } else {
            setPermissions([...permissions, permission]);
        }
    };

    useEffect( () => {
        getUsers();
    }, []);

    async function getUsers (){
    
        try {
            const response = await fetch(`${API_URL}/user`);
            if(!response.ok){
                throw new Error('Failed to fetch users');
            }
            const data = await response.json();
            const usersArray = data.body;
            setUsers(usersArray);
        } catch (error) {
            console.error('Error fetching users: ', error);
        }
    }

    const openModal = (op, id, user, email, permissions ) =>{
        console.log(id);
        setUser('');
        setEmail('');
        setPermissions([]);
        setOperation(op);
        setPassword("");
        if(op === 1){
            setTittle('Registrar nuevo usuario');
        }else if(op === 2){
            setTittle('Editar Usuario');
            setId(id);
            console.log(id);
            setUser(user);
            setEmail(email);
            setPermissions(permissions);
        }
        window.setTimeout(function(){
            document.getElementById('nombre').focus();
    
        }, 500);

    }
    const validar = () =>{
        let parametros;
        let metodo;
        let url;
        if(user.trim() === ''){
            showAlert('Escribe el nombre de usuario', 'warning');
        }else if(email.trim() === ''){
            showAlert('Escribe el email del usuario', 'warning');
        }else if(permissions.length === 0){
            showAlert('Escribe los permisos del usuario', 'warning');
        } else if(operation === 1 && password.trim() === ''){
            showAlert('Escribe la contraseña del usuario', 'warning');
        }else{
            if(operation === 1){
                parametros = {user: user.trim(), email:email.trim(), permissions: permissions, password:password.trim()};
                metodo = 'POST';
                url = `${API_URL}/signup`;
            }else{
                parametros = {id:id, user:user.trim(), email:email.trim(), permissions:permissions};
                metodo = 'PUT';
                url = `${API_URL}/user/${parametros.id}`;
                console.log(parametros.id);
            }
            enviarSolicitud(metodo, parametros, url);
        }
    }
    const enviarSolicitud = async (metodo, parametros, url) =>{
        try {
            const response = await fetch(url, {
                method: metodo,
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    parametros
                ),

            });

            if(!response.ok){
                throw new Error('Network response was not ok');
            }
            
            if(response.ok && operation === 1){
                showAlert("Usuario creado correctamente", "success");
                getUsers();
            }else if(response.ok && operation === 2){
                showAlert("Usuario actualizado correctamente", "success");
                getUsers();
            }else{
                console.log("Something went wrong");
            }
            
        } catch (error) {
            showAlert('Error en la solicitud', 'error');
            console.log(error);
        }
    }
    const handleClickDelete = async (id) => {
        try {
            const usuarioId = id;
            const response = await fetch(`${API_URL}/user/${usuarioId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
            });
            if(response.ok){
                const data = await response.json();
                console.log(data.message);
                showAlert('Usuario Eliminado con éxito', "success");
                getUsers();
            }
        } catch (error) {
            showAlert('Error al eliminar usuario', "error");
        }
    }
    
    return(
        <section className="w-100 m-auto mt-0">
            <div className="container-fluid d-flex flex-column align-items-center justify-content-center w-100">
                <div className="row mt-3 w-100">
                    <div className="col-md-4 offset-4">
                        <div className="d-grid mx-auto">
                            <button onClick={()=>openModal(1)} className="btn btn-dark d-flex justify-content-center align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#modalUsers">
                                <LuPlusCircle className="fs-5"/> Añadir
                            </button>
                        </div>

                    </div>
                </div>
                <div className="row mt-3 w-100 d-flex align-items-center justify-content-center">
                    <div className="col-12 col-lg-8 offset-0 offset-lg-12">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr><th>#</th><th>Username</th><th>Email</th><th>Permission</th></tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {Array.isArray(users) && users.map((user, i)=>(
                                        <tr key={user._id}>
                                            <td>{(i+1)}</td>
                                            <td>{user.user}</td>
                                            <td>{user.email}</td>
                                            <td>{user.permissions.join(', ')}</td>
                                            <td>
                                                <button onClick={()=> openModal(2, user._id, user.user, user.email, user.permissions)} className="btn btn-warning" data-bs-toggle='modal' data-bs-target='#modalUsers'>
                                                    <FaEdit />
                                                </button>
                                                &nbsp;
                                                <button className="btn btn-danger" onClick={() => handleClickDelete(user._id) }>
                                                    <FaTrashAlt />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="modalUsers" className="modal fade" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <label className="h5">{title}</label>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input type="hidden" id="id"/>
                            <div className="input-group mb-3">
                                <span className="input-group-text"><FaUser /></span>
                                <input type="text" id="nombre" className="form-control" placeholder="Nombre" value={user} onChange={(e)=> setUser(e.target.value)} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text"><MdEmail/></span>
                                <input type="email" id="email" className="form-control" placeholder="Email" value={email} onChange={(e)=> setEmail(e.target.value)} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text"><FaLock /></span>
                                <div className="form-check form-check-inline mx-2">
                                    {availablePermissions.map(permission => (
                                        <label key={permission} className="form-check-label mx-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input mx-auto"
                                                checked={permissions.includes(permission)}
                                                onChange={() => handlePermissionChange(permission)}
                                            />

                                            {permission}
                                        </label>
                                    ))}
                                </div>
                                {/* <input type="text" id="permisos" className="form-control" placeholder="Permisos" value={permissions.join(', ')} onChange={(e)=> setPermissions(e.target.value.split(",").map(permission => permission.trim()))} /> */}
                            </div>
                            { operation === 1 && (
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><FaLock /></span>
                                    <input type="password" id="password" className="form-control" placeholder="Contraseña" value={password} onChange={(e)=> setPassword(e.target.value)} />
                                </div>
                                )
                            }
                            
                            <div className="d-grid col-6 mx-auto">
                                <button onClick={()=>validar()} className="btn btn-success  d-flex justify-content-center align-items-center gap-2">
                                    <MdOutlineDataSaverOn className="fs-5" /> Crear
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}